import React, {useEffect, useState, useContext} from "react"
import Layout from "../components/layout"
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
// import 'yup-phone'
import Input from '../UI/Form/input'
import Textarea from '../UI/Form/textarea'
import { gsap } from 'gsap'
import { FirebaseContext } from '../firebase'
import Seo from "../components/seo"

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Please fill in your name'),
  phone: Yup.string().required('${path} is not a valid phone number'),
  email: Yup.string().email('The email entered is not valide').required('Please fill in your email'),
  description: Yup.string().required('Please give us a brief intro of your project'),
})

const Contact = () => {

  const [sent, setSent] = useState(false)

  const isSent = () =>{
    setSent(true)
  }

  const fb = useContext(FirebaseContext)

  const sendForm = (values) => {
    
    const {name, email, phone, description } = values

    fb.db.collection("bhtform").add({
        name: name,
        email: email,
        phone: phone,
        description: description
    })

    const message = "Notre équipe analyse votre demande. Nous vous contacterons bientôt pour de plus amples discussions."

    const prenom = name

    const subject = "Merci de votre intérêt "

    fetch('https://us-central1-elearning-9cb37.cloudfunctions.net/sendEmail', {
      method: 'POST',
      body: JSON.stringify({email, prenom, subject, message})
    }).then(res => {
      return res.json();
    })
    
  }

  useEffect(() => {
    !sent && gsap.to(formRef.children, {opacity: 1, duration: .8, y: 20, ease: "back", stagger: .1})
    sent && gsap.from(".form-sent", {opacity: 0, duration: .8, y: 20, ease: "back"})
  },)

  let formRef = null

  return(
    <Layout>
      <Seo title="T&eacute;l&eacute;m&eacute;decine - Consultation en ligne - Haiti "  />
      <div className="page-container">
        <h2 className="color-0 center">Contactez-nous!</h2>
        <section id="contact-contain">
          <div className="container2 ">
            <p className="section-body2 color-2 center">
              Il vous faut plus d'informations? &Eacute;crivez-nous ici. Nous nous ferons une joie de vous informer d'avantage
              <span role="img" aria-labelledby="smile">😃</span>.
            </p>
            {
              sent
              ?
                <div className="form-sent center color-1">
                  <h4>Votre formulaire a été envoyé avec succès <span role="img" aria-labelledby="clap">👏</span>. Nous vous contacterons sous peu. Merci.</h4>
                </div>
              :
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  phone: '+',
                  description: ''
                }}
                validationSchema={ContactSchema}
                onSubmit={(values, actions) =>{
                  sendForm(values)
                  actions.resetForm()
                  isSent()
                }}
              >
                {({isSubmitting, isValid, handleReset}) => (
                  <Form className="contact-form" ref={ el => formRef = el}>
                    <Field
                      type='text'
                      name='name'
                      placeholder='Votre nom ...'
                      component={Input}
                    />
                    <Field
                      type='text'
                      name='phone'
                      placeholder="Votre numero de telephone ..."
                      component={Input}
                    />
                    <Field
                      type='email'
                      name='email'
                      placeholder='Votre address email ...'
                      component={Input}
                    />
                    <Field
                      type='text'
                      name='description'
                      placeholder='Votre message ...'
                      component={Textarea}
                    />
                    <div className="center">
                      <button className="mybtn center color-0-bg color-3" type='submit'>Envoyer</button>
                    </div>
                    
                  </Form>           
                )}
              </Formik>
            }
          </div>
        </section>
      </div>
    </Layout> 
  )
}

export default Contact
